@tailwind base;
@tailwind components;
@tailwind utilities;

/* hides scrollbar */
::-webkit-scrollbar {
    display: none;
  }
  
  html {
    scroll-behavior: smooth;
  }